import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './NoSearchResultsMaybe.module.css';
import LatestListings from '../../../containers/Listings/LatestListings';

const NoSearchResultsMaybe = props => {
  const { listingsAreLoaded, totalItems, location, resetAll } = props;
  const hasNoResult = listingsAreLoaded && totalItems === 0;
  const hasSearchParams = location.search?.length > 0;
  return hasNoResult ? (
    <>
      <div className={css.noSearchResults}>
        <h1>
          <FormattedMessage id="SearchPage.noResults" />
        </h1>
        <p>
          <FormattedMessage id="SearchPage.noResultsDescription" />
        </p>
        <br />
        {hasSearchParams ? (
          <button className={css.resetAllFiltersButton} onClick={e => resetAll(e)}>
            <FormattedMessage id={'SearchPage.resetAllFilters'} />
          </button>
        ) : null}
      </div>
      <LatestListings notitle={true} />
    </>
  ) : null;
};

export default NoSearchResultsMaybe;
